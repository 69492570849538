@import "../constants/colors";

.internal-link-component {
  display: flex;
  color: $white;
  text-decoration: none;

  &.active {
    color: $blue;
  }

  svg {
    margin-right: 10px;
  }
}
