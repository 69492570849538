@import "../constants/colors";

.external-link-component {
  color: $white;
  text-decoration: none;

  &.active {
    color: $blue;
  }
}
