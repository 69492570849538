$navy: #001A3C;
$blue: #0087FF;
$white: #FFFFFF;
$grey: #DEDBD6;
$yellow: #FFD45C;

$siteBackground: $white;
$mistGrey: #E5E5E5;
$dolphinGrey: #EEEEEE; /* Dad joke */
$border: #B2ADA6;
