@import "./general/constants/colors";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  background: $siteBackground;
  color: darkslategrey;
  font-family: arial, sans-serif;
  font-size: 16px;
}

li {
  list-style-type: none;
}
