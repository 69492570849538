@import "../constants/colors";

.links-menu-item-component {
  font-size: 16px;
  padding: 18px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  &.centered {
    text-align: center;
  }
}
