@import "../../constants/colors";

.sr-monthly-graph {
  width: 200px;
  height: 45px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 auto;

  &__bar {
    width: 100%;
    height: 0px;
    background: $yellow;
    font-size: 6px;
    transition: .3s;
    padding-top: 2px;

    &.large {
      font-size: 12px;
    }
  }

  &__bar-wrap {
    width: 12px;
    height: 100%;
    text-align: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 8px;

    &.large {
      width: 20px;
      font-size: 16px;
    }
  }

  &.large {
    height: 150px;
    width: 400px;
  }
}
